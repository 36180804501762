import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "@css/app.css";

import Swiper from "swiper";
import { Navigation, Pagination, Thumbs } from "swiper/modules";

const carouselThumbs = new Swiper(".js-carousel-thumbs", {
  spaceBetween: 10,
  slidesPerView: 5,
  freeMode: true,
  watchSlidesProgress: true,
});
const carousel = new Swiper(".js-carousel", {
  modules: [Navigation, Thumbs],
  spaceBetween: 10,
  slidesPerView: "auto",
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  thumbs: {
    swiper: carouselThumbs,
  },
});

const productCarousel = new Swiper(".js-product-carousel", {
  modules: [Navigation],
  spaceBetween: 10,
  slidesPerView: 1,
  centeredSlides: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

// Put Alpine last, so that it has access to other JS modules
import Alpine from "alpinejs";
window.Alpine = Alpine;
Alpine.start();

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}
